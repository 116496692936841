import './FooterSection.css';
import {isMobile} from 'react-device-detect';
import {Facebook, X, GitHub} from '@mui/icons-material';

export default function FooterSection({
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isExtraLargeDevice,
    isPortrait }) {
    return (
        <footer 
        ismobile={isMobile.toString()}
        isportrait={isPortrait.toString()}
        issmalldevice={isSmallDevice.toString()}
        ismediumdevice={isMediumDevice.toString()}
        islargedevice={isLargeDevice.toString()}
        isextralargedevice={isExtraLargeDevice.toString()} >
        <div className="social">
            {/*<a href="#privacy">Privacy</a>*/}
            <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer" title="Link to Facebook">
            <Facebook />
            </a>
            <a id="profile-link" href="https://github.com/" target="_blank" rel="noopener noreferrer" title="Link to Github">
            <GitHub />
            </a>
            <a href="https://x.com/" target="_blank" rel="noopener noreferrer" title="Link to Twitter/X">
            <X />
            </a>
        </div>
            <div className="statements">
                <a href="#privacy" target="_blank" rel="noopener noreferrer">Privacy</a>
                <a href="#terms" target="_blank" rel="noopener noreferrer">Terms</a>
            </div>
            {/*<a href="#" class="logo"><img src="/favicon.ico" alt="Logo"></img></a>*/}
            <p>2024 © Kritters Workshop, LLC. All rights reserved.</p>
        </footer>
    );
}

