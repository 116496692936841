import './App.css';
/*import HeaderSection from './components/HeaderSection.js'*/
import LogoSection from './components/LogoSection.js'
import AboutSection from './components/AboutSection.js'
import ContactSection from './components/ContactSection.js'
import FooterSection from './components/FooterSection.js'
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
 function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      }
    }, []);
  
    return windowDimensions;
  }

function App() {
    //const closest = useClosestMedia();
    const {height,width} = useWindowDimensions(); 
    console.log(width + "," + height);
    const isSmallDevice = useMediaQuery({ query: '(max-width: 767px)' });
    const isMediumDevice = useMediaQuery({ query: '(min-width: 768px) and (max-width: 992px)' });
    const isLargeDevice = useMediaQuery({ query: '(min-width: 993px) and (max-width: 1249px)' });
    const isExtraLargeDevice = useMediaQuery({ query: '(min-width: 1250px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  
    isSmallDevice && console.log("App: is a SmallDevice ");
    isMediumDevice && console.log("App: is a MediumDevice ");
    isLargeDevice && console.log("App: is a LargeDevice ");
    isExtraLargeDevice && console.log("App: is a ExtraLargeDevice ");
    console.log("App: is portrait: " + isPortrait);
    return (
        <div id="back" className="App-holder" >
            {/*<HeaderSection
                isSmallDevice={isSmallDevice}
                isMediumDevice={isMediumDevice}
                isLargeDevice={isLargeDevice}
                isExtraLargeDevice={isExtraLargeDevice}
                isPortrait={isPortrait} />*/}
            <LogoSection
                isSmallDevice={isSmallDevice}
                isMediumDevice={isMediumDevice}
                isLargeDevice={isLargeDevice}
                isExtraLargeDevice={isExtraLargeDevice}
                isPortrait={isPortrait} />
            <AboutSection
                isSmallDevice={isSmallDevice}
                isMediumDevice={isMediumDevice}
                isLargeDevice={isLargeDevice}
                isExtraLargeDevice={isExtraLargeDevice}
                isPortrait={isPortrait} />
            <ContactSection
                isSmallDevice={isSmallDevice}
                isMediumDevice={isMediumDevice}
                isLargeDevice={isLargeDevice}
                isExtraLargeDevice={isExtraLargeDevice}
                isPortrait={isPortrait} />
            <FooterSection
                isSmallDevice={isSmallDevice}
                isMediumDevice={isMediumDevice}
                isLargeDevice={isLargeDevice}
                isExtraLargeDevice={isExtraLargeDevice}
                isPortrait={isPortrait} />
        </div>
    );
}

export default App;

