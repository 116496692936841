import './LogoSection.css';
import {isMobile} from 'react-device-detect';
//import React, { useEffect, useState } from 'react';
import React, { useRef, useEffect } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

export default function LogoSection({
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isExtraLargeDevice,
    isBrowser,
    isPortrait }) {
    const scrollRef = useRef(null);
    var timer = null;

    useEffect(() => { 
        let timerEvent = () => {
            scrollRef.current.className = "scroll-indicator";
        };
        timer = setTimeout(timerEvent,1000);
        const listener = () => {
            clearTimeout(timer);
            const moon = document.getElementById("moon");
            const value = window.scrollY;
            moon.style.top = value * 1.05 + "px";
            scrollRef.current.className = "scroll-indicator hide";
            timer = setTimeout(timerEvent, 3000);
        };
        window.addEventListener('scroll', listener);        
        return () => {
            window.removeEventListener('scroll', listener);
        };
    },[]);
    //const query = useMediaQuery('lg');
    return (
        <section className='logoSection' ismobile={isMobile.toString()} isportrait={isPortrait.toString()}>
            <img src="/stars.png" alt="" id="stars"></img>
            <img src="/trees-bg.png" alt="" id="trees-bg"></img>

            <BrowserView>
                <img src="/moon.png" alt="" id="moon"></img>
            </BrowserView>
            <MobileView>
                { isPortrait ? <img src="/moon.png" alt="" id="moon"></img> : 
                    <img src="/moon_mobile_rotate.png" alt="" id="moon"></img> }
            </MobileView>

            <img src="/trees.png" alt="" id="trees"></img>

            <BrowserView>
                <img id="logo" src="/logo_transparent_lg.png" alt=""></img>
            </BrowserView>
            <MobileView>
                { isPortrait ? <img id="logo" src="/logo_transparent_lg.png" alt=""></img> : 
                    <img id="logo" src="/logo_transparent_mobile_rotate.png" alt=""></img> }
            </MobileView>
            <div ref={scrollRef} className="scroll-indicator hide">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </section>
        
    );
}