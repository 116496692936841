import './ContactSection.css';
import {isMobile} from 'react-device-detect';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Phone from '@mui/icons-material/Phone';
import Mail from '@mui/icons-material/Mail';

export default function ContactSection ({
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isExtraLargeDevice,
    isBrowser,
    isPortrait }) {
    return (
        <section  className='contactSection' 
        ismobile={isMobile.toString()}
        isportrait={isPortrait.toString()}
        issmalldevice={isSmallDevice.toString()}
        ismediumdevice={isMediumDevice.toString()}
        islargedevice={isLargeDevice.toString()}
        isextralargedevice={isExtraLargeDevice.toString()} >
            <div id="newsletter">
                <h1>Sign up to learn about new products and updates.</h1>
                <div className="newsletter-stack" >
                    <TextField placeholder="Enter your email" variant="outlined" sx={{margin:'20px 5px'}} color="secondary" />
                    <Button variant="contained" disableElevation size='small' sx={{margin:'20px 5px',backgroundColor:'#a216fc'}}>Subscribe</Button>
                </div>
            </div>
            {/*<Divider sx={{borderColor:'#2b1055'}} />*/}
            <div id="contact-info">
                <h2>Contact Us</h2>
                <List className="contact-info-stack">
                    <ListItem>
                        <ListItemIcon>
                            <Phone style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText>
                            (302) 401-1230
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>                            
                            <Mail style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText>
                            Kritters Workshop, LLC<br/>
                            2810 N Church St PMB 981380<br/>
                            Wilmington, Delaware 19802
                        </ListItemText>
                    </ListItem>
                </List>
            </div>
        </section>
    )
};