import './AboutSection.css';
import {isMobile} from 'react-device-detect';
//import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

export default function AboutSection({
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isExtraLargeDevice,
    isPortrait }) {
    return (
        <section id="about" className='aboutSection' 
            ismobile={isMobile.toString()}
            isportrait={isPortrait.toString()}
            issmalldevice={isSmallDevice.toString()}
            ismediumdevice={isMediumDevice.toString()}
            islargedevice={isLargeDevice.toString()}
            isextralargedevice={isExtraLargeDevice.toString()} >
            <h2>Upgrade your Halloween</h2>
            {/*<p>
                Kritters Workshop, LLC specializes in crafting <b>software-defined Halloween props and haunts</b>.
            </p>*/}
            <section id="showcase">
                <div className="column-1 box">
                    <p><b className="jl-font">Reanimate Your Minions</b></p>
                    <img className='smart-pumpkin' ismobile={isMobile.toString()} src='pumpkin-reanimated.png'></img>
                    <p className="description">
                        Turn your existing, traditional props into <b>smart props</b>.
                    </p>
                </div>
                <div className="column-2 box">
                    <p><b className="jl-font">Recruit New Minions</b></p>
                    <img className='recruit-pumpkin' ismobile={isMobile.toString()} src='pumpkin-recruit.png'></img>
                    <p className="description">
                        A collection of props that blend cutting-edge technology with Halloween.
                    </p>
                </div>
            </section>
        </section>
    );
}